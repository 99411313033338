import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SliceZone from '../components/SliceZone';

export const ContactUsPage = ({data}) => {
    if (!data) return null;
    const header = data.prismicHeaderitem || {};
    const footer = data.prismicFooteritem || {};
    const {lang, type, url} = header || {};
    const alternateLanguages = header.alternate_languages || [];
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    };

    const contactus = data.prismicContactus || {};

    return (
        <Layout activeDocMeta={activeDoc} body={header} bodyFooter={footer} fullPage={true}>
            <SliceZone slices={contactus.data.body} />
        </Layout>
    );
};

export const query = graphql`
    query ContactUsQuery($lang: String) {
        prismicFooteritem(lang: {eq: $lang}) {
            url
            type
            lang
            alternate_languages {
                lang
                type
            }
            data {
                about {
                    text
                }
                allrights {
                    text
                }
                item1 {
                    text
                }
                item2 {
                    text
                }
                item3 {
                    text
                }
                item4 {
                    text
                }
                item5 {
                    text
                }
                item6 {
                    text
                }
                item7 {
                    text
                }
                privacy {
                    text
                }
                terms {
                    text
                }
                clientinformation {
                    text
                }
                impressum {
                    text
                }
            }
        }
        prismicHeaderitem(lang: {eq: $lang}) {
            lang
            type
            url
            alternate_languages {
                lang
                type
            }
            data {
                button {
                    text
                }
                item1 {
                    text
                }
                item2 {
                    text
                }
                item3 {
                    text
                }
                item4 {
                    text
                }
                item5 {
                    text
                }
                item6 {
                    text
                }
                solutions {
                    solution_name {
                        text
                    }
                }
                cookie_consent {
                    raw
                }
            }
        }
        prismicContactus(lang: {eq: $lang}) {
            data {
                body {
                    ... on PrismicContactusBodyTextureSection {
                        id
                        slice_type
                        primary {
                            title {
                                text
                            }
                            emailtext {
                                text
                            }
                            buttontext {
                                text
                            }
                        }
                    }
                    ... on PrismicContactusBodyHeroSection {
                        id
                        slice_type
                        primary {
                            title {
                                text
                            }
                            subtitle {
                                text
                            }
                            button {
                                text
                            }
                            button_link {
                                url
                            }
                            background_image {
                                url
                            }
                            pulse_animation
                        }
                    }
                    ... on PrismicContactusBodyContactSection {
                        id
                        slice_type
                        primary {
                            viewonmap {
                                text
                            }
                            section_title {
                                text
                            }
                            section_number {
                                text
                            }
                            section_name {
                                text
                            }
                            section_description {
                                raw
                            }
                            phone_placeholder {
                                text
                            }
                            phone {
                                text
                            }
                            openhours_title {
                                text
                            }
                            openhours {
                                raw
                            }
                            name_placeholder {
                                text
                            }
                            name {
                                text
                            }
                            message_placeholder {
                                text
                            }
                            message {
                                text
                            }
                            email_placeholder {
                                text
                            }
                            email {
                                text
                            }
                            contact_title {
                                text
                            }
                            contact {
                                raw
                            }
                            button_text {
                                text
                            }
                            bettercallme {
                                text
                            }
                            address_title {
                                text
                            }
                            address {
                                raw
                            }
                            thank_you {
                                raw
                            }
                        }
                    }
                }
            }
            type
            url
            lang
        }
    }
`;

export default ContactUsPage;
